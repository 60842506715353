<template>
    <div class="stage-choose-plan">
        <app-loader v-if="is_loading" />
        <app-error v-model="errors.show" :message="errors.message" />


        <div class="group">
            <div class="column">
                <div class="control prod">
                    <app-dropdown-select
                        v-model="plan.order_type"

                        label="Order Type"

                        key-value="value"
                        key-title="title"

                        :required="true"

                        :options="order_types"
                        :error="errors.fields.order_type"

                        @change="errors.fields.order_type = null"
                    />
                </div>
            </div>
            <div class="column">
                <div class="control">
                    <app-dropdown-select
                        v-model="term_filter_value"

                        label="Term"

                        key-value="key"
                        key-title="title"

                        :options="term_filter.options"
                        :error="errors.fields.term_filter_options"

                        @change="errors.fields.term_filter_options = null"
                    />
                </div>
            </div>

            <!--
                <div class="column">
                    <div v-if="!is_loading" class="control">
                        <button v-if="!product_catalog_options || (Object.keys(product_catalog_options).length == 0)" class="btn btn-primary btn-small" @click="getProductCatalogOptions">Reload Products</button>
                    </div>
                </div>
            -->
        </div>

        <div class="group">
            <div class="columnwide">
                <div class="control">
                    <app-dropdown-select
                        v-model="plan.product_catalog"

                        label="Choose Product (filtered by Order Type and Term)"

                        key-value="uuid"
                        key-title="title"

                        :required="true"

                        :options="dynamic_plan_options"
                        :multiline="true"
                        :error="errors.fields.product_catalog"

                        @change="changeProduct"
                    />
                </div>
            </div>
            <div class="columnthin">
                <div class="control">
                    <app-input-datepicker
                        v-model="plan.rfs_date"
                        :future-only="true"

                        label="RFS Date"
                        :required="true"

                        :error="errors.fields.rfs_date"

                        @change="errors.fields.rfs_date = null"
                    />
                </div>
            </div>
            <!--
                <div class="column">
                    <div v-if="!is_loading" class="control">
                        <button v-if="!product_catalog_options || (Object.keys(product_catalog_options).length == 0)" class="btn btn-primary btn-small" @click="getProductCatalogOptions">Reload Products</button>
                    </div>
                </div>
            -->
        </div>

        <div class="group">
            <div class="column">
                <div class="control radios">
                    <app-radio-buttons
                        v-model="plan.is_service_managed"
                        :items="service_types"

                        :inline="true"
                    />
                </div>
            </div>
            <div class="column" v-if="show_ont_list">
                <div class="control">
                    <app-dropdown-select
                        v-if="have_multiple_ont_ids"

                        v-model="plan.OrderExistingONTID"

                        label="ONT"

                        key-value="value"
                        key-title="title"

                        :options="ont_ids"
                    />

                    <app-dropdown-select
                        v-else-if="have_intact_service_ids"

                        v-model="plan.OrderExistingProductID"

                        label="Intact Service"

                        key-value="value"
                        key-title="title"

                        :options="intact_service_ids"
                    />
                </div>
            </div>
        </div>

        <div class="group">
            <div class="columndouble">
                <div class="control">
                    <app-input
                        v-model="plan.Additional_Details"

                        label="Additional Details"

                        :error="errors.fields.Additional_Details"

                        @change="errors.fields.Additional_Details = null"
                    />
                </div>
            </div>
        </div>

        <div class="group">
            <div class="column">
                <div class="control">
                    <app-input
                        v-model="plan.LAN_IP_Range"

                        label="LAN IP Range"

                        :error="errors.fields.LAN_IP_Range"

                        @change="errors.fields.LAN_IP_Range = null"
                    />
                </div>
            </div>
            <div class="column" v-if="plan.is_service_managed">
                <div class="control">
                    <app-input
                        v-model="plan.WAN_IP"

                        label="WAN IP"

                        :error="errors.fields.WAN_IP"

                        @change="errors.fields.WAN_IP = null"
                    />
                </div>
            </div>
            <div class="column" v-if="!plan.is_service_managed">
                <div class="control">
                    <app-input
                        v-model="plan.VRF_Details"

                        label="VRF Details"

                        :error="errors.fields.VRF_Details"

                        @change="errors.fields.VRF_Details = null"
                    />
                </div>
            </div>
        </div>

        <div class="group" v-if="plan.is_service_managed">
            <div class="column">
                <div class="control">
                    <app-radio-buttons
                        v-model="plan.DHCP_Scope"
                        :items="dhcp_scope_options"

                        label="DHCP Scope:"

                        :inline="true"
                    />

                </div>
            </div>
            <div class="column" v-if="plan.DHCP_Scope">
                <div class="control">
                    <app-input
                        v-model="plan.IP_Helper_Details"

                        label="IP Helper Details"

                        :error="errors.fields.IP_Helper_Details"

                        @change="errors.fields.IP_Helper_Details = null"
                    />
                </div>
            </div>
        </div>

        <button class="btn btn-primary btn-small" :disabled="!is_plan_selected" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appRadioButtons from '@/components/app-radio-buttons'
import appDropdownSelect from '@/components/app-dropdown-select'
import appInputDatepicker from '@/components/app-input-datepicker'

import FibreValidator from '@/validators/fibre-validator'

export default {
    components: {
        appLoader,
        appError,
        appInput,
        appRadioButtons,
        appDropdownSelect,
        appInputDatepicker,
    },

    props: {
        broadband: { type: Object, required: true },
    },

    data() {
        return {

            loading: {
                product_catalog_options: false,

                sim_card_id_validation: false,
            },

            plan: {
                OrderExistingONTID: null,
                OrderExistingProductID: null,

                rfs_date: null,

                order_type: 'ConnectPrimary',

                is_service_managed: false,

                LAN_IP_Range: '',
                VRF_Details: '',
                Additional_Details: '',

                WAN_IP: '',

                DHCP_Scope: false,

                IP_Helper_Details: '',

                product: {},
                product_catalog: '',
            },

            term_filter: {
                options: [
                    { key: '', title: 'All Plans (No filter)' },
                    { key: '0', title: 'Open Term' },
                    { key: '12', title: '12 month term' },
                    { key: '24', title: '24 month term' },
                    { key: '36', title: '36 month term' },
                    { key: '48', title: '48 month term' },
                    { key: '60', title: '60 month term' },
                ],
            },

            term_filter_value: '',

            product_catalog_options: [],

            errors: {
                fields: {},
            },
        }
    },

    methods: {
        init() {
            this.plan = {
                ...this.plan,
                ...this.broadband.plan,
            }

            this.getProductCatalogOptions()
        },

        confirm() {
            if (this.validation()) {
                this.$emit('ready', { plan: this.plan })

                this.$emit('go-next')
            }
        },

        changeProduct(product) {
            this.plan.product = this.product_catalog_options[product]
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                product_catalog: { rule: 'ProductCatalog', message: 'Please, choose product'    },
                order_type:      { rule: 'OrderType',      message: 'Please, choose order type' },
                rfs_date:        { rule: 'RFSDate',        message: 'Please, choose RFS date'   },
            }

            for (const key in fields) {
                if (FibreValidator.isRuleExists(fields[key].rule)) {
                    if (FibreValidator.isInvalid(fields[key].rule, this.plan[key], fields[key].message)) {
                        this.errors.fields[key] = FibreValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        getProductCatalogOptions() {
            this.loading.product_catalog_options = true
            let ServiceAvailabilityUUID = this.broadband.PrequalDetails ? this.broadband.PrequalDetails.UUID : '';
            const params = {
                SPID: this.current_spid,
                ServiceAvailabilityUUID,
            }

            this.$store.dispatch('api_ufbprod/GetUFBPlans', params)
                .then(({ UFBPlans }) => {
                    let plans = {}

                    if (UFBPlans) {
                        for (let i = 0, len = UFBPlans.length; i < len; i++) {
                            plans[UFBPlans[i].UUID] = {
                                ...UFBPlans[i],
                                uuid: UFBPlans[i].UUID,
                                title: `${ (UFBPlans[i].TermLength && UFBPlans[i].TermLength != '0') ? '['+UFBPlans[i].TermLength+'mo]' : ''} ${ UFBPlans[i].Name } ($${ UFBPlans[i].Cents % 100 == 0 ? UFBPlans[i].Cents / 100 : (UFBPlans[i].Cents / 100).toFixed(2) })`,
                            }
                        }
                    }

                    this.product_catalog_options = plans
                    this.loading.product_catalog_options = false
                })
                .catch(error => {
                    console.error(error)

                    this.loading.product_catalog_options = false
                })

        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'getFibreOrderTypesOrdering',
        ]),

        is_loading() {
            return this.loading.product_catalog_options || this.loading.sim_card_id_validation
        },

        dynamic_plan_options() {
            // if (this.term_filter_value == '') {
            //     // There is no filter
            //     return this.product_catalog_options
            // }

            const keys = Object.keys(this.product_catalog_options)
            if (keys.length == 0) {
                // Return the default catalog if we can't find product catalog options
                return this.product_catalog_options
            }

            let selectedUUID = this.plan && this.plan.product && this.plan.product.UUID ? this.plan.product.UUID : ''
            let foundSelectedUUID = false

            const OrderTypeObject = this.getFibreOrderTypesOrdering[this.plan.order_type]

            let filterPlanOptions = {}
            for (let i = 0; i < keys.length; i++) {
                if (!this.product_catalog_options[keys[i]]) {
                    continue
                }

                if (OrderTypeObject) {
                    if (OrderTypeObject.requiresPrimary && !this.product_catalog_options[keys[i]].PrimaryOffer) {
                        // console.log('dropping plan '+this.product_catalog_options[keys[i]].Name+' due to OrderType requiresPrimary and product !PrimaryOffer', this.product_catalog_options[keys[i]])
                        continue
                    }
                    if (!OrderTypeObject.requiresPrimary && this.product_catalog_options[keys[i]].PrimaryOffer) {
                        // console.log('dropping plan '+this.product_catalog_options[keys[i]].Name+' due to OrderType !requiresPrimary and product PrimaryOffer', this.product_catalog_options[keys[i]])
                        continue
                    }
                }

                if (this.term_filter_value) {
                    if (this.product_catalog_options[keys[i]].TermLength != this.term_filter_value) {
                        // console.log('dropping plan due to TermLength filter ('+this.term_filter_value+') and product TermLength '+this.product_catalog_options[keys[i]].TermLength)
                        continue
                    }
                }
                if (selectedUUID && this.product_catalog_options[keys[i]].UUID == selectedUUID) {
                    foundSelectedUUID = true
                }
                filterPlanOptions[keys[i]] = this.product_catalog_options[keys[i]]
            }

            if (selectedUUID && !foundSelectedUUID) {
                // If the current selected plan isn't in the filtered list, remove the current selection
                this.plan.product_catalog = ''
            }

            return filterPlanOptions
        },

        is_plan_selected() {
            return (this.plan && this.plan.product && this.plan.product.UUID && this.plan.product_catalog) ? true : false
        },

        service_types() {
            return [
                {
                    value: false,
                    label: 'Unmanaged Service',
                },
                {
                    value: true,
                    label: 'Managed Service',
                },
            ]
        },

        dhcp_scope_options() {
            return [
                {
                    value: true,
                    label: 'Yes',
                },
                {
                    value: false,
                    label: 'No',
                },
            ]
        },

        order_types() {
            return Object.entries(this.getFibreOrderTypesOrdering)
                .map(([ key, val ]) => val );
        },

        show_ont_list() {
            if (this.plan.order_type != 'ConnectAdditionalONT' && (this.have_multiple_ont_ids || this.have_intact_service_ids)) {
                return true
            }
            return false
        },

        ont_ids() {
            if (this.broadband.location.FibreInfo && Array.isArray(this.broadband.location.FibreInfo.ONTIDs)) {
                if (Array.isArray(this.broadband.location.FibreInfo.ONTSerials) && this.broadband.location.FibreInfo.ONTSerials.length == this.broadband.location.FibreInfo.ONTIDs.length) {
                    return this.broadband.location.FibreInfo.ONTIDs.map( (id, index) => (
                        {
                            value: id,
                            title: id + ' - ' + this.broadband.location.FibreInfo.ONTSerials[index],
                        }))
                }
                return this.broadband.location.FibreInfo.ONTIDs.map(id => ({ value: id, title: id }))
            }
            return []
        },
        have_multiple_ont_ids() {
            return this.ont_ids.length > 1
        },

        intact_service_ids() {
            return this.broadband.location.FibreInfo && Array.isArray(this.broadband.location.FibreInfo.IntactServiceIDs)
                ? this.broadband.location.FibreInfo.IntactServiceIDs.map(id => ({ value: id, title: id }))
                : []
        },
        have_intact_service_ids() {
            return this.intact_service_ids.length > 0
        },
    },
}
</script>

<style lang="scss">
.stage-choose-plan {
    max-width: 1170px;

    .group {
        display: flex;
        margin-bottom: 30px;

        .column {
            width: 100%;
            max-width: 370px;
            margin-right: 30px;

            .control {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            .radios {
                margin-top: 10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .columnwide {
            width: 100%;
            max-width: 540px;
            margin-right: 30px;

            .control {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .columnthin {
            width: 100%;
            max-width: 200px;
            margin-right: 30px;

            .control {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .columndouble {
            width: 100%;
            max-width: 770px;
            margin-right: 30px;

            .control {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    & > .app-radio-buttons {
        margin-bottom: 30px;
    }

    .managed-service,
    .unmanaged-service {
        margin-bottom: 30px;
    }

    .managed-service {
        .form-group {
            .form-controls {
                width: 100%;
                max-width: 370px;

                margin-bottom: 30px;

                .app-radio-buttons {
                    display: flex;

                    & > .label {
                        margin: 0 30px 0 0;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .unmanaged-service {
        .form-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .form-controls {
                width: 100%;
                max-width: calc(100% / 3 - 20px);
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .stage-choose-plan {
        .unmanaged-service {
            .form-group {
                flex-direction: column;

                .form-controls {
                    max-width: 370px;

                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .stage-choose-plan {
        .app-radio-buttons {
            &.inline {
                .radio-btns {
                    justify-content: flex-start;

                    .radio-button {
                        margin-right: 24px;
                    }
                }
            }
        }

        & > .app-radio-buttons {
            margin-bottom: 24px;
        }

        .managed-service,
        .unmanaged-service {
            margin-bottom: 24px;
        }

        .managed-service {
            .form-group {
                .form-controls {
                    margin-bottom: 24px;

                    .app-radio-buttons {
                        & > .label {
                            margin: 0 24px 0 0;
                        }
                    }
                }
            }
        }

        .unmanaged-service {
            .form-group {
                .form-controls {
                    margin-bottom: 24px;
                }
            }
        }
    }
}
</style>