<template>
    <app-dialog class="dialog-broadband-add-success"
        v-if="show"
        v-model="show"

        :max-width="maxWidth"

        @closes="closes"

        ref="dialog"
    >
        <template #head>
            <div class="head">
                <slot name="head">
                    <h2 class="heading">Success!</h2>
                </slot>
            </div>
        </template>

        <template #form>
            <p>Fibre with order ID {{ orderId }} has been added.</p>
        </template>

        <template #btns>
            <button class="btn btn-primary" @click="close">Ok</button>
        </template>
    </app-dialog>
</template>

<script>
import appDialog from '@/components/app-dialog'

import BroadbandValidator from '@/validators/broadband-validator'

export default {
    props: {
        value: { required: true },
        maxWidth: { type: [Number, String], default: 610 },

        orderId: { required: true },
    },

    components: {
        appDialog,
    },

    methods: {
        close() {
            this.show = false
            this.closes()
        },

        closes(with_button) {
            this.$emit('closes', with_button)
        },
    },

    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
// .app-dialog.app-dialog.dialog-broadband-add-success {}
</style>